@keyframes slideright {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.overlay {
  &-panel {
    display: flex;
    background-color: white;
    padding: 1rem;
  }
}

.sidenav {
  &-panel {
    height: calc(100vh - 60px);
    width: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    background-color: white;

    margin-top: 4rem !important;
    max-width: 16.5rem;
    overflow: overlay;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      margin-top: 4rem !important;
      max-width: 100vw;
    }

    &,
    & * {
      @include media-breakpoint-down(sm) {
        font-size: 1.25rem !important;
      }
    }
  }

  &-backdrop {
    &--transparent {
      background: transparent;
    }
  }
}
