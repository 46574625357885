.mat-calendar {
  width: 100%;

  &-body {
    &-cell {
      &-content {
        border-width: 0 !important;
      }
    }

    &-selected {
      background-color: var(--bs-primary);
      color: white;
    }
  }
}
