.cdk-dialog {
    &-panel {
        background-color: white;
        border-radius: 5px;
    }

    &-no-background {
        .mat-dialog-container {
            background: transparent;
            box-shadow: none;
        }
    }

    &-light-background {
        background: rgba(255, 255, 255, 0.85);
    }

    &--notification-panel {
        width: 100%;
    }
}
