
/*
 * Container style
 */
 .ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }
  
  /*
   * Scrollbar rail styles
   */
  .ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    height: 15px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
  }
  
  .ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 12px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
  }
  
  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
  }
  
  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
  }
  
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    /*background-color: #eee;*/
    opacity: 0.9;
  }
  
  /*
   * Scrollbar thumb styles
   */
  .ps__thumb-x {
    /*background-color: #aaa;*/
    background-color: var(--divider) !important;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 8px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
  }
  
  .ps__thumb-y {
    /*background-color: #aaa;*/
    background-color: var(--divider) !important;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 8px;
    /* there must be 'right' for ps__thumb-y */
    margin: 0 auto;
    /*left: 2px;*/
    /*right: 2px;*/
    right: 0;
    /* please don't change 'position' */
    position: absolute;
  }
  
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    /*background-color: #999;*/
    background-color: var(--divider) !important;
    height: 6px !important;
  }
  
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    /*background-color: #999;*/
    background-color: var(--divider) !important;
  }
  
  /* MS supports */
  @supports (-ms-overflow-style: none) {
    .ps {
      overflow: auto !important;
    }
  }
  
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
      overflow: auto !important;
    }
  }
  
  perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:hover,
  .ps__rail-y:hover {
    background-color: transparent !important;
    width: 10px !important;
  }
  
  perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y,
  .ps__rail-y,
  perfect-scrollbar > .ps.ps--scrolling-x > .ps__rail-x {
    background-color: transparent !important;
  }
  
  perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:hover > .ps__thumb-y,
  perfect-scrollbar > .ps.ps--active-y > .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y {
    width: 6px;
    background-color: var(--divider) !important;
  }
  
  perfect-scrollbar > .ps.ps--active-y > .ps__rail-y > .ps__thumb-y,
  perfect-scrollbar > .ps.ps--active-x > .ps__rail-x > .ps__thumb-x {
    background-color: var(--divider) !important;
  }
  
  perfect-scrollbar > .ps.ps--active-x > .ps__rail-x:hover,
  .ps__rail-x:hover {
    background-color: transparent !important;
    height: 10px !important;
  }
  
  perfect-scrollbar > .ps.ps--active-x > .ps__rail-x:hover > .ps__thumb-x,
  perfect-scrollbar > .ps.ps--active-x > .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x {
    height: 6px;
    background-color: var(--divider) !important;
  }
  
  .ps__rail-x {
    //margin-left: 255px;
    background-color: transparent !important;
  }
  
