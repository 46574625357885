@import 'variables';

.mat-tab {
  &-header {
    .mat-ink-bar {
      height: 0.25rem;
      background: $celeste-500;
    }
  }
}

.mat-tab-label {
  margin-right: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  opacity: 1 !important;
  background-color: $gray-200;

  &,
  span,
  button {
    color: $blue-700;
  }

  .badge {
    background-color: $blue-700;
    color: white;
  }

  &-active {
    background-color: $blue-700;

    &,
    span,
    button {
      color: white;
    }

    .badge {
      background-color: $gray-200;
      color: $blue-700;
    }
  }
}

.mat-tab-list {
  .cdk-program-focused {
    background-color: $blue-700 !important;
  }
}
