@import './_fonts.scss';
@import 'material-symbols';
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// These are the defaults, but you can override any values
$font-family-sans-serif: 'Poppins', -apple-system, system-ui, BlinkMacSystemFont,
  Roboto, 'Helvetica Neue', Arial, sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

// Color system

// scss-docs-start blue-color-variables
$blue-100: #D3EDF8 !default;
$blue-200: #A7DBF1 !default;
$blue-300: #50B8E4 !default;
$blue-400: #24A6DD !default;
$blue-500: #228FCD !default;
$blue-600: #2079BC !default;
$blue-700: #1E62AC !default;
$blue-800: #154375 !default;
$blue-900: #103359 !default;
// scss-docs-end blue-color-variables

// scss-docs-start cyan-color-variables
$cyan-100: #D7FCFF !default;
$cyan-200: #A4F8FE !default;
$cyan-300: #72F5FE !default;
$cyan-400: #3FF1FD !default;
$cyan-500: #0AC9D6 !default;
$cyan-600: #08A5AF !default;
$cyan-700: #058189 !default;
$cyan-800: #035D62 !default;
$cyan-900: #01393C !default;
// scss-docs-end cyan-color-variables

// scss-docs-start gray-color-variables
$white: #FFFFFF !default;
$gray-100: #F9FAFB !default;
$gray-200: #EAEAEA !default;
$gray-300: #C5C6CA !default;
$gray-400: #ABADB1 !default;
$gray-500: #919398 !default;
$gray-600: #76797F !default;
$gray-700: #5C6067 !default;
$gray-800: #42464E !default;
$gray-900: #282C35 !default;
$black: #000000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start green-color-variables
$green-100: #A5F3B1 !default;
$green-200: #82E391 !default;
$green-300: #5ED371 !default;
$green-400: #3BC450 !default;
$green-500: #17B430 !default;
$green-600: #139527 !default;
$green-700: #0F761E !default;
$green-800: #0B5716 !default;
$green-900: #07380D !default;
// scss-docs-end green-color-variables

// scss-docs-start yellow-color-variables
$yellow-100: #FFE3AD !default;
$yellow-200: #FFD687 !default;
$yellow-300: #FFC961 !default;
$yellow-400: #FFBC3A !default;
$yellow-500: #FFAF14 !default;
$yellow-600: #D99410 !default;
$yellow-700: #B2790D !default;
$yellow-800: #8C5E09 !default;
$yellow-900: #654305 !default;
// scss-docs-end yellow-color-variables

// scss-docs-start red-color-variables
$red-100: #F2BABA !default;
$red-200: #EC9A9A !default;
$red-300: #E67979 !default;
$red-400: #E15959 !default;
$red-500: #DB3838 !default;
$red-600: #B52C2C !default;
$red-700: #8F2020 !default;
$red-800: #681313 !default;
$red-900: #420707 !default;
// scss-docs-end red-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #103359 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #bf2323 !default;
$orange: #fd7e14 !default;
$yellow: #ffaf14 !default;
$green: #0cedfc !default;
$teal: #20c997 !default;
$cyan: #0cedfc !default;
$celeste: #24a6dd !default;
$accent: #0ac9d6 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'black': $black,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
) !default;

// fusv-disable
$celeste-100: #d7fcff !default;
$celeste-200: #a4f8fe !default;
$celeste-300: #72f5fe !default;
$celeste-400: $accent !default;
$celeste-500: #0ac9d6 !default;
$celeste-600: #08a5af !default;
$celeste-700: #058189 !default;
$celeste-800: #035d62 !default;
$celeste-900: #01393c !default;

$celeste-500-secondary: $celeste !default;

// scss-docs-start theme-color-variables
$primary: $blue-700 !default;
$secondary: $blue-400 !default;
$success: $green-500 !default;
$accent: $cyan-400 !default;
$info: $gray-200 !default;
$warning: $yellow-500 !default;
$danger: $red-500 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;
$border-style: solid !default;
$border-color: $gray-300 !default;
$border-color-translucent: rgba($black, 0.175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.5rem !default;
//pendiente por definir
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

//btn-input Variables
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1.5rem !default;
$input-btn-font-family: null !default;
$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;

/**
  *   Dropdown
  *
 */

$dropdown-color: map-get($colors, emphasis-high);
$dropdown-bg: map-get($colors, surface-2);
$dropdown-border-color: map-get($colors, border);
$dropdown-divider-bg: $dropdown-border-color;

$dropdown-link-color: map-get($colors, emphasis-high);
$dropdown-link-hover-color: map-get($colors, emphasis-high);
$dropdown-link-hover-bg: map-get($colors, divider);

$dropdown-link-active-icon-color: map-get(
  $colors,
  'green'
); /* custom property */
$dropdown-link-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$dropdown-link-active-icon-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>"); /* custom property */

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,

  25: $spacer * 0.75,
  3: $spacer,
  35: $spacer * 1.25,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;
// scss-docs-end spacer-variables-maps

// scss-docs-start font-variables

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * 0.75rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

$arc-plus-btn-color: #43cb61;
$community-color: #3ff1fd;
$community-color-active: #72f5fe;
$community-color-dark: #43474f;
$menu-btn-color: #3d4451;
$menu-btn-border-color: #a7a2a2;

@function pxToRem($px) {
  @return ($px / 16) + rem;
}