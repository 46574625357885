/* override to use app-select instant */
.dropdown-menu {
  display: block !important;
  position: relative !important;
  padding: $spacer;
  font-size: 0.875rem;
  top: 0;
  background-clip: border-box;

  .dropdown-item {
    border-radius: $border-radius;
    white-space: break-spaces;

    &:not(:last-child) {
      margin-bottom: $spacer;
    }

    &.focus,
    &.highlight,
    &:hover {
      background-color: var(--support-2);
      color: $dropdown-link-hover-color;
    }
  }

  &:not(.multiple) {
    .dropdown-item {
      &:active {
        background-image: unset !important;
        padding-right: $spacer !important;
      }
      &.active {
        padding-right: $input-height-inner;
        background-image: escape-svg($dropdown-link-active-icon);
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
        background-color: unset !important;
      }
    }
  }
}
