mat-stepper:not(.home-stepper) {
  .mat-stepper-horizontal-line {
    display: none !important;
  }
  
  .mat-step-header {
    flex-direction: column;
    justify-content: center;
    border-bottom: 0 solid $blue-700;
    transition: 0.2s;
    height: auto !important;
  
    .mat-step-icon {
      background: $gray-400;
      margin-right: 0 !important;
  
      &-selected {
        background: $blue-700 !important;
      }
    }
  
    .mat-step-label {
      color: $gray-400 !important;
      padding: 0.5rem 0;
      text-align: center;
  
      &-selected {
        color: $blue-700 !important;
        font-weight: bold;
      }
    }
  
    &[aria-selected='true'] {
      border-bottom-width: 4px;
    }
  
    &[aria-labelledby="is-complete-step"] {
  
      border-bottom: 4px solid $blue-700;
  
      .mat-step-label {
        color:  $blue-700!important;
        font-weight: 700;
      }
  
      .mat-step-icon {
        background:  $blue-700 !important;
      }
    }
  }
  
  .mat-horizontal-stepper-header-container {
    border-bottom: 0.5px solid $gray-300;
    margin-bottom: 2rem;
    justify-content: space-around;
  }
  
  .mat-horizontal-content-container {
    padding: 0 0 1.5rem !important;
  }  
}
