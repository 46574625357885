.card-light {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.card-big {
  gap: 8px;
  //max-width: 20.56rem;
  //min-height: 15.875rem;
  border-radius: 0.5rem;
  /* Rectangle 858 */
  backdrop-filter: blur(0.063rem);
  background-repeat: no-repeat;
  background-size: cover;
}

.card-small {
  // min-width: 20.56rem;
  // height: 7.44rem;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-container {
  padding: 0 8.75rem 0 8.75rem;
}

.windows-modal-suport-documents {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.star_yellow {
  color: $yellow;
}

.star_blue {
  color: $blue-700;
}

.star_light {
  color: $gray-300;
}

.icons_nav {
  color: $white !important;
  font-size: 2.5rem;
}

.icons {
  font-size: 2.5rem;
}

.icon-tooltip {
  margin: 0px;
}

.icon-total {
  &,
  span {
    font-size: 16px !important;
    margin-right: 0px;
    color: $blue-400;
    display: block;
    margin-left: 0px;
    margin-bottom: 1px;
  }
}

.colum-tooltip {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: start;
  align-items: center;
}

.mb-statement-info {
  margin-bottom: 50px;
}

.negative-margin-100 {
  margin-bottom: -5rem;
}

.custom-filter {
  z-index: 3;
  position: absolute;
  width: 55%;
  right: 10px;
}

.highlighted-date {
  background-color: $yellow-500 !important;
  border-radius: 6.25rem;
}

.total_footer {
  font-weight: $font-weight-bold;
}

.notifications-shadow {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04) inset;
}

.btn-sm-text {
  font-size: $font-size-sm !important;
}

.text_important {
  color: $blue-800;
}

.form-icon {
  &,
  span {
    width: 20px;
    height: 20px;
    font-size: 20px !important;
    margin-right: 5px;
    color: $gray-500;
  }
}

.label-survay{
  font-weight: 300 !important;
  font-size: 1rem !important;
  margin-left: 10px;
}

.error-production-location{
  font-size: 0.75rem;
  margin-top: 0px;
}
