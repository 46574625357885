@import '@angular/cdk/overlay-prebuilt.css';
@import './perfect-scrollbar.scss';
@import './material/icons';

@import 'bootstrap/scss/functions';
@import './_variables.scss';
@import './_utils.scss';
// variables overwrite
@import 'bootstrap/scss/variables';
@import './form/input/_input.scss';
@import './form/input/_dropdown.scss';
@import './form/input/form-group';
@import './form/_text-area.scss';
@import './form/buttons/_buttons.scss';
@import './colors/_background-colors.scss';
@import './sizing/_sizing.scss';

@import 'bootstrap/scss/maps';
@import './colors/_text-colors.scss';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/bootstrap';

@import './overlay/overlay';
@import './overlay/dialog';

@import './material/calendar';
@import './material/stepper';
@import './reports/reports';
@import './material/tabs';
@import './form/forms';

body {
  margin: 0;
  overflow: hidden;

  p {
    margin: 0;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: initial;
    line-height: initial;
  }

  input[type='radio' i] {
    margin: 3px 3px 3px 5px;
  }

  a {
    text-decoration: none;
  }
}

/* barra de scroll */
::-webkit-scrollbar {
  width: 6px;
  display: block;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-500);
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Placeholder */
::-webkit-input-placeholder {
  color: $gray-500 !important;
}
:-moz-placeholder {
  color: $gray-500 !important;
}
::-moz-placeholder {
  color: $gray-500 !important;
}
:-ms-input-placeholder {
  color: $gray-500 !important;
}

.spacer {
  flex: 1 1 auto;
}

input::placeholder {
  color: $gray-600;
}

.material-symbols-outlined {
  font-variation-settings: 'OUTLINED' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.material-symbols-outlined.fill {
  font-variation-settings: 'FILL' 1;
}

.no-padding .mat-dialog-container {
  padding: 0 !important;
}

.no-margin figure {
  margin: 0 !important;
}

.no-scrolls .mat-dialog-container {
  overflow: hidden !important;
}

.element-menu-option {
  color: $blue-400;
}

.form-check-input {
  border-color: $blue-400;

  &[type='checkbox'] {
    border-color: $blue-600;
  }
}

.form-check-input:checked {
  background-color: $blue-600;
  border-color: $blue-600;
}

// global tab styles
.mat-tab {
  &-header {
    .mat-ink-bar {
      height: 0.25rem;
      background: $celeste-500;
    }
  }
}

.mat-tab-label {
  margin-right: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  opacity: 1 !important;
  background-color: $gray-200;
  color: $blue-700;
}

.mat-tab-list {
  .cdk-program-focused {
    background-color: $blue-700 !important;
  }
}

.mat-tab-label-active {
  background-color: $blue-700;
  color: white;
}

.mat-tab-header {
  z-index: 1;
}

.mat-tab-group.mat-primary mat-tab-header .mat-ink-bar {
  background-color: $celeste-500;
}

.cdk-dialog-panel {
  margin: 1rem;
}

@font-face {
  font-family: 'Adobe Handwriting';
  src: url('../assets/fonts/AdobeHandwriting-Ernie.ttf') format('truetype');
}

.transparent-modal {
  background: transparent;
}

.transparent-modal > mat-dialog-container {
  background: transparent !important;
  box-shadow: none !important;
  .transparent-background-dialog {
    mat-dialog-container {
      background-color: transparent;
      box-shadow: none;
      padding: 0.688rem;
    }
  }

  .modal-general-contract {
    margin-left: 15.625rem;
    overflow-y: auto;

    @include media-breakpoint-down(sm) {
      margin-top: 5.69rem;
      padding-bottom: 5.69rem;
      max-width: 100vw !important;
    }
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }
}

.carousel-indicators-top {
  .indicators {
    top: 1rem !important;
  }
}
