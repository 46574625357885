.cdk {
    &-icon-button {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }
}

button:disabled {
    opacity: 0.75;
    color: gray;
    pointer-events: none;
}