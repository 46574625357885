@import '../../variables';

.form-group {
  label {
    margin-bottom: 0.25rem;
    color: $gray-800;
  }

  &-required {
    label {
      &::after {
        content: '*';
        font-size: 14px;
        margin-left: 0.25rem;
      }
    }
  }

  &-fill {
    label {
      color: $blue-400;
      font-weight: 600;
    }
  }

  &-content {
    flex-direction: row;
    align-items: center;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid $blue-400;
    background-color: $gray-100;
    transition: 0.2s;

    &:not(.fg-textarea){
      height: 40px;
    }

    &:focus {
      border-bottom-width: 2px;
    }

    span[cdkicon='outlined'] {
      &.material-icons-outlined {
        color: $blue-400;
      }
    }

    .cdk-input-prefix,
    .cdk-input-suffix {
      max-width: 30px;
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &,
      * {
        color: $blue-400;
      }
    }

    .cdk-icon-button {
      .mat-icon,
      span {
        font-size: 15px;
        height: 15px;
      }
    }

    input, textarea {
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      padding: 0 0.5rem;

      &,
      &::placeholder,
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: $gray-500 !important;
        font-size: 16px;
      }

      &:disabled {
        opacity: 0.75;
      }
    }

    textarea {
      min-height: 120px;
      max-height: 120px;
    }

    &-invalid {
      border-bottom-color: var(--bs-danger);
      border-bottom-width: 2px;
    }
  }

  &-invalid .form-group-content {
    border-bottom-color: var(--bs-danger);
    border-bottom-width: 2px;
  }

  .feedback-container {
    display: block;
    padding: 0.25rem 0;

    p {
      font-size: 0.75em;
    }
  }
}

.form-status-message {
  font-size: 12px;
}

.cdk-form {
  padding: 0.5rem 0 0.5rem;
}
