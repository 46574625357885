@import 'material-icons/iconfont/material-icons.scss';

.material-symbols {
  &-outlined,
  &-rounded,
  &-sharp {
    font-weight: 300;

    &.filled {
      font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 24;
    }
  }
}
