textarea {
  /* COLORS/$gray-200 */
  background: #eaeaea;
  /* COLORS/$blue-400 */
  border-bottom: 1px solid #24a6dd;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 8px 8px 0px 0px;
}
